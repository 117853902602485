<template>
  <div v-if="$route.path == '/'">
    <NavBar />
  </div>
  <div v-else>
    <HeaderView />
  </div>
  <notifications position="top right" />
  <div
    class="flex items-center justify-center h-screen spin-content"
    v-if="loading"
  >
    <pulse-loader class="" :color="color" :loading="loading"></pulse-loader>
  </div>

  <router-view />

  <FooterView/>
</template>
<script>
import NavBar from "@/components/Default/partials/NavBar.vue";
import HeaderView from "@/components/Default/partials/HeaderView.vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import FooterView from "@/components/Default/partials/Footer.vue";
export default {
  name: "AppView",
  data() {
    return {
      home: "Home",
      color: "#98d1c8",
    };
  },
  components: {
    NavBar,
    HeaderView,
    FooterView,
    PulseLoader,
  },

  mounted() {
    document.title = "Spidi - Meilleur marché en ligne";
  },
  computed: {
    loading() {
      return this.$store.getters.loading;
    },
  },
};
</script>
