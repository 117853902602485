<template>
  <div>
    <HomeBanner />
    <div class="container max-w-full p-8 mx-auto mt-10" v-if="settings">
      <div class="max-w-full mx-auto">
        <h3 class="mb-3 text-2xl font-extrabold">{{ $t("stores") }}</h3>
        <div class="grid grid-cols-2 gap-4 lg:grid-cols-4" v-if="vendors">
          <router-link
            :to="{
              name: 'VendorHome',
              params: {
                id: vendor.id,
                slug: vendor.name
                  ? sanitizeTitle(vendor.name)
                  : sanitizeTitle(vendor.slug),
              },
            }"
            class="flex flex-row items-center p-2 border border-gray-200 rounded-lg hover:bg-gray-200"
            v-for="vendor in vendors"
            :key="vendor.id"
          >
            <div
              class="flex items-center justify-center p-2 rounded-full"
              :style="{ 'background-color': vendor.color }"
            >
              <img v-if="vendor" :src="vendor.logo" class="w-12" alt="" />
            </div>
            <div class="ml-2">
              <h1 class="text-sm font-bold">{{ vendor.name }}</h1>
              <p class="mt-1 text-xs font-light text-gray-500">
                {{ truncate(vendor.description, 40) }}
              </p>
            </div>
          </router-link>
        </div>
        <div class="grid grid-cols-1 mt-10 mb-10 md:grid-cols-2 md:gap-10">
          <a href="" class="flex flex-col">
            <img
              :src="settings.strings.website.websiteFooterImage"
              class="h-[30rem] w-[30rem]"
              alt=""
            />
            <h3 class="text-2xl font-medium tracking-wide">
              {{ settings.strings.website.websiteHeaderSubtitle }}
            </h3>
            <p class="underline">{{ $t("addRestaurant") }}</p>
          </a>
          <a href="" class="flex flex-col">
            <img
              :src="settings.strings.website.websiteHeaderImage"
              class="h-[30rem] w-[30rem]"
              alt=""
            />
            <h3 class="text-2xl font-medium tracking-wide">
              {{ settings.strings.website.websiteHeaderTitle }}
            </h3>
            <p class="underline">
              {{ $t("deliverWith") }} {{ settings.strings.company_name }}
            </p>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import HomeBanner from "@/components/Default/layouts/HomeBanner.vue";
export default {
  name: "HomeView",

  components: {
    HomeBanner,
  },
  data() {
    return {
      vendors: null,
      settings: null,
      // base_url: this.$store.state.baseUrl,
      base_url: this.$store.state.baseUrl,
      search: null,
      types: null,
      currentPlace: null,
      currentLocation: null,
    };
  },
  mounted() {
    let selectedLanguage = localStorage.getItem("selectedLanguage");
    const headers = {
      lang: selectedLanguage,
    };

    this.$store.dispatch("fetchSettings", this.items).then(() => {
      this.settings = this.$store.state.settings;
      this.currency = this.settings.strings.currency;
    });

    this.currentLocation = JSON.parse(localStorage.getItem("currentAddress"));
    
    if (!this.currentLocation) {
      
      if (navigator.geolocation) {
        
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const currentPlace = {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            };
            fetch(
              `https://maps.googleapis.com/maps/api/geocode/json?latlng=${currentPlace.latitude},${currentPlace.longitude}&key=${this.settings.strings.google_maps_key}`
            )
              .then((response) => response.json())
              .then((data) => {
                if (data.results && data.results.length > 0) {
                  localStorage.setItem(
                    "currentAddress",
                    JSON.stringify(data.results[0])
                  );
                  const locationName = data.results[0].formatted_address;
                  this.setPlace(locationName);
                  this.currentPlace = locationName;
                } else {
                  console.error("No results found");
                }
              })
              .catch((error) => {
                console.error("Error fetching location data:", error);
              });
          },
          (error) => {
            console.error("Error getting location:", error.message);
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    }

    this.$store.commit("loading", true);
    axios
      .get(this.base_url + "api/vendor/types", { headers })
      .then((response) => {
        this.vendors = response.data;
        this.$store.commit("loading", false);
      })
      .catch((error) => {
        if (error.response && error.response.status === 429) {
          setTimeout(() => {
            this.$store.commit("loading", true);
            this.mounted();
          }, 1000);
        } else {
          console.log(error);
        }
      });
  },

  methods: {
    truncate(str, n) {
      return str.length > n ? str.substr(0, n - 1) + "..." : str;
    },

    sanitizeTitle(title) {
      var slug = "";
      // Change to lower case
      var titleLower = title.toLowerCase();
      // Letter "e"
      slug = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, "e");
      // Letter "a"
      slug = slug.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, "a");
      // Letter "o"
      slug = slug.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, "o");
      // Letter "u"
      slug = slug.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, "u");
      // Letter "d"
      slug = slug.replace(/đ/gi, "d");
      // Trim the last whitespace
      slug = slug.replace(/\s*$/g, "");
      // Change whitespace to "-"
      slug = slug.replace(/\s+/g, "-");

      return slug;
    },
  },
};
</script>
