<template>
  <div
    class="flex items-center w-11/12 h-[48px] px-4 py-2 bg-[#EEEEEE] rounded-full border border-gray-100"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      fill="currentColor"
      class="w-4 h-4 mr-2"
    >
      <path
        fill-rule="evenodd"
        d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
        clip-rule="evenodd"
      />
    </svg>

    <input
      type="text"
      class="flex-1 block p-2 bg-transparent border-none focus:border-none focus:ring-0 placeholder:text-gray-400"
      placeholder="Nourriture, épicerie, boissons, etc."
      @focus="toggleModal"
      v-model="search"
    />
  </div>
  <div
    v-if="showModal"
    class="fixed inset-0 z-50 flex justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none"
  >
    <div class="relative w-full h-full p-4 md:w-10/12 md:h-auto">
      <!-- Modal content -->
      <div class="relative bg-white rounded-lg shadow">
        <button
          type="button"
          class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
          v-on:click="toggleModal()"
          data-modal-toggle="authentication-modal"
        >
          <svg
            aria-hidden="true"
            class="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
          <span class="sr-only">Close modal</span>
        </button>
        <div class="px-6 py-6 lg:px-8">
          <h3 class="mb-4 text-xl font-medium text-gray-900">
            {{ $t("pages.search") }}
          </h3>
          <div
            class="flex items-center w-11/12 h-[48px] px-4 py-2 bg-[#EEEEEE] rounded-full border border-gray-100"
          >
            <input
              type="text"
              class="flex-1 block p-2 bg-transparent border-none focus:border-none focus:ring-0 placeholder:text-gray-400"
              placeholder="Nourriture, épicerie, boissons, etc."
              v-model="search"
            />
            <div
              v-if="settings"
              @click="getSearchResult"
              class="flex items-center justify-center p-2 rounded-full cursor-pointer"
              :style="{ 'background-color': settings.colors.primaryColor }"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="currentColor"
                class="w-4 h-4"
              >
                <path
                  fill-rule="evenodd"
                  d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
          </div>

          <div v-if="products.length <= 0">
            <p class="mt-5">Aucun produit ou fournisseur</p>
          </div>
          <div v-else class="flex flex-col w-full py-5">
            <div
              class="flex flex-row py-5 mx-auto space-x-5 md:py-8 md:space-x-24"
            >
              <div class="">
                <a
                  @click="getAllProducts"
                  class="text-center cursor-pointer hover:underline hover:text-gray-500"
                  >Products</a
                >
              </div>
              <div class="">
                <a
                  @click="getAllVendors"
                  class="text-center cursor-pointer hover:underline hover:text-gray-500"
                  >Vendors</a
                >
              </div>
            </div>

            <div v-if="showVendors">
              <div
                class="grid gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3"
              >
                <a
                  class="text-gray-500 hover:text-gray-600 hover:underline"
                  :href="
                    $router.resolve({
                      name: 'DefaultVendor',
                      params: {
                        id: vendor.id,
                        slug: sanitizeTitle(vendor.name),
                      },
                    }).href
                  "
                  v-for="vendor in vendors"
                  :key="vendor.id"
                >
                  <div class="w-full">
                    <img
                      v-if="vendor"
                      v-bind:src="vendor.logo"
                      class="w-full h-[9rem] rounded-lg object-cover"
                      alt=""
                    />
                    <div class="mt-2">
                      <h1 class="text-black">
                        {{ truncate(vendor.name, 100) }}
                      </h1>
                      <p class="mt-1 text-xs font-light text-gray-500">
                        {{ vendor.delivery_time ?? "0" }} mins away
                      </p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div v-else>
              <div
                class="grid gap-6 place-items-center sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4"
              >
                <a
                  :href="
                    $router.resolve({
                      name: 'DefaultProduct',
                      params: {
                        id: product.id,
                        slug: sanitizeTitle(product.name),
                      },
                    }).href
                  "
                  v-for="product in products"
                  :key="product.id"
                  class="w-60 h-80"
                >
                  <div>
                    <div
                      class="p-1 bg-gray-50 h-[180px] w-[180px] flex items-center justify-center"
                    >
                      <img
                        v-if="product"
                        v-bind:src="product.photo"
                        class="object-contain w-full h-[160px] mx-auto"
                        alt=""
                      />
                    </div>
                    <div class="flex justify-between mt-2">
                      <p class="text-sm font-medium capitalize">
                        {{ truncate(product.name, 100) }}
                      </p>
                    </div>
                    <p
                      class="mt-0 space-x-1 text-xs font-light"
                      v-if="product.discount_price"
                    >
                      <span class=""
                        >{{ currency
                        }}{{ formatCurrency(product.discount_price) }}</span
                      >
                      <span class="text-gray-500 line-through"
                        >{{ currency }}{{ formatCurrency(product.price) }}</span
                      >
                    </p>
                    <p class="text-xs font-light" v-else>
                      {{ currency }}{{ formatCurrency(product.price) }}
                    </p>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div
            class="flex items-center justify-between px-4 py-3 mt-5 bg-white border-t border-gray-200 sm:px-6"
          >
            <div class="flex justify-between flex-1 sm:hidden">
              <a
                @click="prevPage"
                class="relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
              >
                Previous
              </a>
              <a
                @click="nextPage"
                class="relative inline-flex items-center px-4 py-2 ml-3 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
              >
                Next
              </a>
            </div>
            <div
              class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between"
            >
              <div>
                <p class="text-sm text-gray-700" v-if="products">
                  Affichage
                  {{ " " }}
                  <span class="font-medium">{{ pageNo }}</span>
                  {{ " " }}
                  à
                  {{ " " }}
                  <span class="font-medium">{{ pagination.per_page }}</span>
                  {{ " " }}
                  de
                  {{ " " }}
                  <span class="font-medium">{{ pagination.last_page }}</span>
                  {{ " " }}
                  résultats
                </p>
              </div>
              <div>
                <nav
                  class="relative z-0 inline-flex -space-x-px rounded-md shadow-sm"
                  aria-label="Pagination"
                >
                  <button
                    :class="
                      page === 1
                        ? ' relative disabled:bg-gray-300 inline-flex items-center px-2 py-2 text-sm font-medium text-gray-500 border border-gray-300 rounded-l-md hover:bg-gray-50'
                        : 'relative inline-flex items-center px-2 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-l-md hover:bg-gray-50'
                    "
                    @click="prevPage"
                    class=""
                  >
                    <span class="sr-only">Previous</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-4 h-4"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M15.75 19.5 8.25 12l7.5-7.5"
                      />
                    </svg>
                  </button>

                  <button
                    @click="nextPage"
                    class="relative inline-flex items-center px-2 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-r-md hover:bg-gray-50"
                  >
                    <span class="sr-only">Next</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-4 h-4"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="m8.25 4.5 7.5 7.5-7.5 7.5"
                      />
                    </svg>
                  </button>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="showModal" class="fixed inset-0 z-40 bg-black opacity-25"></div>
</template>

<script>
import axios from "axios";
export default {
  name: "InputField",
  props: {
    vendorTypeRouteId: String,
  },
  data() {
    return {
      search: "",
      showModal: false,
      vendors: null,
      settings: null,
      base_url: this.$store.state.baseUrl,
      products: null,
      currency: null,
      selectedProducts: null,
      types: null,
      pageNo: 1,
      page: 1,
      perPage: 20,
      pagination: null,
      showVendors: false,
      lat: null,
      lng: null,
      vendorRouteId: this.vendorTypeRouteId,
    };
  },
  mounted() {
    this.currentLocation = JSON.parse(localStorage.getItem("currentAddress"));
    if (this.currentLocation) {
      this.lat = this.currentLocation.geometry.location.lat;
      this.lng = this.currentLocation.geometry.location.lng;
    }

    this.$store.dispatch("fetchSettings", this.items).then(() => {
      this.settings = this.$store.state.settings;
      this.currency = this.settings.strings.currency;
    });

    axios
      .get(
        `${this.base_url}api/products?page=${this.pageNo}&vendor_type_id=${this.vendorRouteId}&lat=${this.lat}&long=${this.lng}`
      )
      .then((response) => {
        this.products = response.data.data;
        this.pagination = response.data;
      })
      .catch((error) => console.log(error));
  },
  methods: {
    formatCurrency(value) {
      return new Intl.NumberFormat("en-US").format(value);
    },
    prevPage() {
      if (this.showVendors == false) {
        this.$store.commit("loading", true);
        this.pageNo--;
        axios
          .get(
            `${this.base_url}api/products?page=${this.pageNo}&vendor_type_id=${this.vendorRouteId}&lat=${this.lat}&long=${this.lng}`
          )
          .then((response) => {
            this.$store.commit("loading", false);
            this.products = response.data.data;
          });
      } else {
        this.$store.commit("loading", true);
        this.pageNo--;
        axios
          .get(
            `${this.base_url}api/vendors?page=${this.pageNo}&vendor_type_id=${this.vendorRouteId}&lat=${this.lat}&long=${this.lng}`
          )
          .then((response) => {
            this.$store.commit("loading", false);
            this.vendors = response.data.data;
          });
      }
    },

    nextPage() {
      if (this.showVendors == false) {
        this.$store.commit("loading", true);
        this.pageNo++;
        axios
          .get(
            `${this.base_url}api/products?page=${this.pageNo}&vendor_type_id=${this.vendorRouteId}&lat=${this.lat}&long=${this.lng}`
          )
          .then((response) => {
            this.$store.commit("loading", false);
            this.products = response.data.data;
          });
      } else {
        this.$store.commit("loading", true);
        this.pageNo++;
        axios
          .get(
            `${this.base_url}api/vendors?page=${this.pageNo}&vendor_type_id=${this.vendorRouteId}&lat=${this.lat}&long=${this.lng}`
          )
          .then((response) => {
            this.$store.commit("loading", false);
            this.vendors = response.data.data;
          });
      }
    },
    toggleModal: function () {
      this.showModal = !this.showModal;
    },

    getAllProducts() {
      this.showVendors = false;
      this.$store.commit("loading", true);
      //   ?vendor_type_id=" + 2
      axios
        .get(
          `${this.base_url}api/products?page=${this.pageNo}&vendor_type_id=${this.vendorRouteId}&lat=${this.lat}&long=${this.lng}`
        )
        .then((response) => {
          this.$store.commit("loading", false);
          this.products = response.data.data;
          // console.log(this.products)
        })
        .catch((error) => console.log(error));
    },

    getAllVendors() {
      this.showVendors = true;
      //   ?vendor_type_id=" + 2
      this.$store.commit("loading", true);
      axios
        .get(
          `${this.base_url}api/vendors?page=${this.pageNo}&vendor_type_id=${this.vendorRouteId}&lat=${this.lat}&long=${this.lng}`
        )
        .then((response) => {
          this.$store.commit("loading", false);
          this.vendors = response.data.data;
          // console.log(this.vendors)
        })
        .catch((error) => console.log(error));
    },

    getSearchResult() {
      this.$store.commit("loading", true);
      axios
        .get(this.base_url + "api/search?keyword=" + this.search)
        .then((response) => {
          //   console.log(response)
          this.$store.commit("loading", false);
          let productSearch;
          let vendorSearch;
          productSearch = response.data.products.data;
          vendorSearch = response.data.vendors.data;
          this.products = productSearch;
          this.vendors = vendorSearch;
        })
        .catch((error) => console.log(error));
    },
    truncate(str, n) {
      return str.length > n ? str.substr(0, n - 1) + "..." : str;
    },

    sanitizeTitle(title) {
      var slug = "";
      // Change to lower case
      var titleLower = title.toLowerCase();
      // Letter "e"
      slug = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, "e");
      // Letter "a"
      slug = slug.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, "a");
      // Letter "o"
      slug = slug.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, "o");
      // Letter "u"
      slug = slug.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, "u");
      // Letter "d"
      slug = slug.replace(/đ/gi, "d");
      // Trim the last whitespace
      slug = slug.replace(/\s*$/g, "");
      // Change whitespace to "-"
      slug = slug.replace(/\s+/g, "-");

      return slug;
    },
  },
};
</script>
