<template>
  <Disclosure
    :class="{ 'bg-white': isScrolled, 'bg-transparent': !isScrolled }"
    as="nav"
    class="fixed top-0 z-50 w-full h-16 md:px-8"
    v-if="settings"
  >
    <div class="flex items-center justify-between p-2">
      <div class="flex items-center">
        <DisclosureButton
          class="relative inline-flex items-center justify-center p-2 text-gray-400 rounded-md hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
          @click="openSidebar"
        >
          <span class="absolute -inset-0.5" />
          <span class="sr-only">Open main menu</span>
          <Bars3Icon class="block w-6 h-6 font-extrabold text-black" />
          <!-- <XMarkIcon v-else class="block w-6 h-6" aria-hidden="true" /> -->
        </DisclosureButton>
        <a
          href="/"
          class="px-8 text-2xl font-bold text-black"
          :class="{ flex: isScrolled, hidden: !isScrolled }"
        >
          {{ settings.strings.app_name }}
        </a>
      </div>
      <!-- <div
        class="items-center justify-between hidden md:flex md:w-5/12 GMapAutocomplete"
      >
        <div
          :class="{ flex: isScrolled, hidden: !isScrolled }"
          class="flex items-center h-12 p-3 text-black bg-gray-100 md:w-full"
          v-if="settings"
        >
          <svg
            width="24px"
            height="24px"
            fill="none"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            focusable="false"
          >
            <path
              d="M17.5834 5.16602C14.5001 2.08268 9.50008 2.08268 6.41675 5.16602C3.33341 8.24935 3.33341 13.3327 6.41675 16.416L12.0001 21.9993L17.5834 16.3327C20.6667 13.3327 20.6667 8.24935 17.5834 5.16602ZM12.0001 12.416C11.0834 12.416 10.3334 11.666 10.3334 10.7493C10.3334 9.83268 11.0834 9.08268 12.0001 9.08268C12.9167 9.08268 13.6667 9.83268 13.6667 10.7493C13.6667 11.666 12.9167 12.416 12.0001 12.416Z"
              fill="currentColor"
            ></path>
          </svg>
          <GMapAutocomplete
            class="w-full p-2 bg-transparent"
            placeholder="Entrez l'adresse de livraison"
            @place_changed="setPlace"
          >
          </GMapAutocomplete>
        </div>
      </div> -->

      <div class="flex flex-row">
        <a
          href="/login"
          class="flex items-center justify-center w-auto h-12 px-4 py-2 font-medium text-center text-black bg-white rounded-full shadow"
          v-show="isLoggedIn == false"
        >
          <svg
            aria-hidden="true"
            focusable="false"
            viewBox="0 0 26 26"
            class="w-5 h-5"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M18.958 7.042a5.958 5.958 0 11-11.916 0 5.958 5.958 0 0111.916 0zM3.25 21.667c0-3.575 2.925-6.5 6.5-6.5h6.5c3.575 0 6.5 2.925 6.5 6.5v3.25H3.25v-3.25z"
            ></path>
          </svg>
          <span class="ml-2">{{ $t("login.login") }}</span>
        </a>
        <a
          href="/register"
          class="flex items-center justify-center w-auto h-12 p-2 px-4 py-2 ml-4 font-medium text-center text-white bg-black rounded-full shadow"
          type="button"
          v-show="isLoggedIn == false"
        >
          <span class="ml-2">{{ $t("login.SignUp") }}</span>
        </a>
        <div class="flex flex-row" v-show="isLoggedIn">
          <button
            @click="openCartSidebar"
            class="flex items-center h-12 px-4 py-2 text-sm text-center text-white rounded-full shadow justify-centerw-auto"
            :style="{ 'background-color': settings.colors.primaryColor }"
          >
            <svg
              aria-hidden="true"
              focusable="false"
              viewBox="0 0 16 16"
              class="text-white fill-white h-[20px] w-[20px]"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M3.666 11.333h10.333l1.334-8h-11l-.267-2h-3.4v2h1.667l1.333 8zm1.333 3.334A1.333 1.333 0 105 12a1.333 1.333 0 000 2.667zm9.334-1.334a1.333 1.333 0 11-2.667 0 1.333 1.333 0 012.667 0z"
              ></path>
            </svg>
            <p class="px-1 text-sm">{{ cartItemCount }}</p>
            <span class="">{{ $t("cart.cart") }}</span>
          </button>
        </div>
      </div>
    </div>
    <!-- <div
      :class="{ flex: isScrolled, hidden: !isScrolled }"
      class="flex items-center justify-between h-16 p-3 bg-gray-100 md:hidden md:w-5/12"
      v-if="settings"
    >
      <svg
        width="24px"
        height="24px"
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        focusable="false"
      >
        <path
          d="M17.5834 5.16602C14.5001 2.08268 9.50008 2.08268 6.41675 5.16602C3.33341 8.24935 3.33341 13.3327 6.41675 16.416L12.0001 21.9993L17.5834 16.3327C20.6667 13.3327 20.6667 8.24935 17.5834 5.16602ZM12.0001 12.416C11.0834 12.416 10.3334 11.666 10.3334 10.7493C10.3334 9.83268 11.0834 9.08268 12.0001 9.08268C12.9167 9.08268 13.6667 9.83268 13.6667 10.7493C13.6667 11.666 12.9167 12.416 12.0001 12.416Z"
          fill="currentColor"
        ></path>
      </svg>
      <GMapAutocomplete
        class="w-full p-2 bg-gray-100 border-none placeholder:font-light placeholder:text-gray-400"
        placeholder="Entrez l'adresse de livraison"
        @place_changed="setPlace"
      >
      </GMapAutocomplete>
    </div> -->
  </Disclosure>
  <!-- <LoginModal
    v-show="showModal"
    :showModal="showModal"
    @closeModal="closeModal"
  /> -->
  <Sidebar />
  <CartSideBar />
</template>
<script>
import Sidebar from "@/components/Default/partials/Sidebar.vue";
import CartSideBar from "@/components/Default/partials/CartSideBar.vue";
// import LoginModal from "@/components/Default/widgets/LoginModal.vue";
export default {
  data() {
    return {
      showModal: false,
      isLoggedIn: false,
      vendors: null,
      user: null,
      settings: null,
      search: null,
      products: null,
      currency: null,
      base_url: this.$store.state.baseUrl,
      autocomplete: null,
      currentPlace: null,
      markers: [],
    };
  },
  components: {
    Disclosure,
    // LoginModal,
    Sidebar,
    CartSideBar,
  },

  mounted() {
    this.$store.dispatch("fetchSettings").then(() => {
      this.settings = this.$store.state.settings;
      console.log(this.settings)
    });

    if (localStorage.getItem("authToken")) {
      this.isLoggedIn = true;
      this.user = JSON.parse(localStorage.getItem("user"));
    }

    navigator.geolocation.getCurrentPosition((position) => {
      this.markers.position = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };
    });
  },

  computed: {
    cartItemCount() {
      return this.$store.getters.count;
    },
  },

  methods: {
    // closeModal() {
    //   this.showModal = false;
    // },
    setPlace(place) {
      this.currentPlace = place;
    },

    openSidebar() {
      this.$store.dispatch("openSidebar");
    },

    openCartSidebar() {
      this.$store.dispatch("openCartSidebar");
    },

    logout() {
      localStorage.removeItem("authToken");
      localStorage.removeItem("phone");
      localStorage.removeItem("user");
      this.$router.push("/");
      window.location.href = "/login";
    },

    randomString(length) {
      var result = "";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789~$^*()";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },

    sanitizeTitle(title) {
      var slug = "";
      // Change to lower case
      var titleLower = title.toLowerCase();
      // Letter "e"
      slug = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, "e");
      // Letter "a"
      slug = slug.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, "a");
      // Letter "o"
      slug = slug.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, "o");
      // Letter "u"
      slug = slug.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, "u");
      // Letter "d"
      slug = slug.replace(/đ/gi, "d");
      // Trim the last whitespace
      slug = slug.replace(/\s*$/g, "");
      // Change whitespace to "-"
      slug = slug.replace(/\s+/g, "-");

      return slug;
    },
  },
};
</script>
<script setup>
import { ref, onMounted, onBeforeUnmount } from "vue";
import { Disclosure, DisclosureButton } from "@headlessui/vue";
import { Bars3Icon } from "@heroicons/vue/24/outline";

// const navigation = [
//   { name: "Dashboard", href: "#", current: true },
//   { name: "Team", href: "#", current: false },
//   { name: "Projects", href: "#", current: false },
//   { name: "Calendar", href: "#", current: false },
// ];

const isScrolled = ref(false);

const handleScroll = () => {
  isScrolled.value = window.scrollY > 0;
};

onMounted(() => {
  window.addEventListener("scroll", handleScroll);
});

onBeforeUnmount(() => {
  window.removeEventListener("scroll", handleScroll);
});
</script>
<style>
/* Assuming "GMapAutocomplete" is the parent container */
/* .GMapAutocomplete {
  width: 100%;
} */

/* Assuming the input field inside GMapAutocomplete */
.GMapAutocomplete input {
  /* width: 100%; */
  padding: 0.5rem; /* Adjust as needed */
  border: none;
  outline: none;
  border-radius: 0.25rem; /* Adjust as needed */
  font-size: 1rem; /* Adjust as needed */
  color: #000000; /* Change to desired text color */
}

/* Remove focus styles */
.GMapAutocomplete input:focus {
  border: none;
  box-shadow: none;
}
</style>
