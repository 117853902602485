<template>
  <hr class="mt-10" />
  <div v-if="vendors">
    <div class="container w-full px-4 py-8 mx-auto" v-if="settings">
      <div class="grid py-10 md:gap-5 md:grid-cols-4">
        <div class="flex flex-col col-span-2 mt-3 mb-5 md:mt-0 md:mb-0">
          <div class="flex flex-row md:flex-col">
            <h1 class="w-6/12 text-2xl font-bold text-black md:mb-8">
              {{ settings.strings.app_name }} 
            </h1>

            <div class="flex flex-row items-center w-6/12 space-x-5 md:mt-20">
              <a :href="settings.strings.iosDownloadLink" target="__blank"
                ><img src="/img/app-store.svg" class="md:h-[2.6rem] h-[2rem]"
              /></a>
              <a :href="settings.strings.androidDownloadLink" target="__blank"
                ><img src="/img/play-store.png" class="md:w-36 w-28"
              /></a>
            </div>
          </div>
        </div>
        <div
          class="flex flex-col mt-3 text-black md:mt-0 md:items-center md:w-52"
        >
          <div class="flex flex-col">
            <h1 class="w-6/12 text-l font-bold text-black md:mb-8">
              Liens  
            </h1>
            <a
              class="mb-3 font-light"
              target="__blank"
              :href="`${this.$store.state.baseUrl}register/driver`"
              >{{ $t("footer.sub4") }}</a
            >
            <a
              class="mb-3 font-light"
              target="__blank"
              :href="`${this.$store.state.baseUrl}register/vendor`"
              >{{ $t("footer.sub5") }} {{ settings.strings.app_name }}?</a
            >
            <a href="" class="mb-3 font-light">{{ $t("footer.sub6") }}</a>
            <a href="" class="mb-3 font-light">{{ $t("footer.sub7") }}</a>

            <a
              class="mb-3 font-light"
              target="__blank"
              :href="`https://web.spidi.digital/contact`"
              >{{ $t("Contactez-nous") }}</a
            >
            <a
              class="mb-3 font-light"
              target="__blank"
              :href="`https://web.spidi.digital/blog/`"
              >{{ $t("Blog") }}</a
            >
          </div>
        </div>
        <div class="flex flex-col mt-3 md:mt-0 md:items-center md:w-52">
          <div class="flex flex-col">
            <h1 class="w-6/12 text-l font-bold text-black md:mb-8">
              Qui nous sommes?  
            </h1>
           
            <a
              class="mb-3 font-light"
              target="__blank"
              :href="`https://web.spidi.digital/politique-de-confidentialite`"
              >{{ $t("Politique de confidentialité") }}</a
            >
            <a
              class="mb-3 font-light"
              target="__blank"
              :href="`https://web.spidi.digital/termes-et-conditions`"
              >{{ $t("Termes et conditions") }}</a
            >
            <a
              class="mb-3 font-light"
              target="__blank"
              :href="`https://web.spidi.digital/a-propos-de-nous/`"
              >{{ $t("A propos") }}</a
            >
            <a
              class="mb-3 font-light"
              target="__blank"
              :href="`https://web.spidi.digital/politique-de-remboursement-et-de-retour`"
              >{{ $t("Politique de remboursement") }}</a
            >
            <a
              class="mb-3 font-light"
              target="__blank"
              :href="`https://web.spidi.digital/cookies/`"
              >{{ $t("Cookies") }}</a
            >
            <a
              class="mb-3 font-light"
              target="__blank"
              :href="`https://web.spidi.digital/frequently-asked-questions/`"
              >{{ $t("FAQs") }}</a
            >
            <a
              class="mb-3 font-light"
              target="__blank"
              :href="`https://pay.spidi.digital/`"
              >{{ $t("SpidiPay") }}</a
            >
          </div>
        </div>
      </div>
      <hr class="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />
      <p class="mt-10 font-light">
        {{ settings.strings.website.websiteFooterBrief }}
      </p>
      <div class="flex justify-between mt-2">
        <div class="flex justify-end space-x-2">
          <a
            :href="
              settings.strings.website.social
                ? settings.strings.website.social.fbLink
                : '#'
            "
            ><img src="/img/icons/facebook.png" class="w-4 h-4" alt=""
          /></a>
          <a
            :href="
              settings.strings.website.social
                ? settings.strings.website.social.igLink
                : '#'
            "
            ><img src="/img/icons/instagram.png" class="w-4 h-4" alt=""
          /></a>
          <a
            :href="
              settings.strings.website.social
                ? settings.strings.website.social.twLink
                : '#'
            "
            ><img src="/img/icons/twitter.png" class="w-4 h-4" alt=""
          /></a>
        </div>
        <p class="text-sm font-normal">
          ©️ 2024 {{ settings.strings.company_name }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "FooterView",
  data() {
    return {
      settings: null,
      vendors: null,
      base_url: this.$store.state.baseUrl,
    };
  },

  mounted() {
    this.$store.dispatch("fetchSettings", this.items).then(() => {
      this.settings = this.$store.state.settings;
    });
    let selectedLanguage = localStorage.getItem("selectedLanguage");
    const headers = {
      lang: selectedLanguage,
    };
    this.$store.commit("loading", true);
    axios
      .get(this.base_url + "api/vendor/types", { headers })
      .then((response) => {
        let vendorTypes = response.data;
        this.vendors = vendorTypes.slice(0, 4);
        this.$store.commit("loading", true);
      })
      .catch((error) => console.log(error));
  },

  methods: {
    sanitizeTitle(title) {
      var slug = "";
      // Change to lower case
      var titleLower = title.toLowerCase();
      // Letter "e"
      slug = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, "e");
      // Letter "a"
      slug = slug.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, "a");
      // Letter "o"
      slug = slug.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, "o");
      // Letter "u"
      slug = slug.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, "u");
      // Letter "d"
      slug = slug.replace(/đ/gi, "d");
      // Trim the last whitespace
      slug = slug.replace(/\s*$/g, "");
      // Change whitespace to "-"
      slug = slug.replace(/\s+/g, "-");

      return slug;
    },
  },
};
</script>
