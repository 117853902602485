<template>
  <div>
    <!-- Your main content -->

    <!-- Sidebar -->
    <div v-if="showCartSidebar" class="fixed inset-0 z-50 overflow-hidden">
      <div class="absolute inset-0 overflow-hidden">
        <div
          class="absolute inset-0 transition-opacity bg-gray-500 bg-opacity-75"
          @click="closeCartSidebar"
        ></div>

        <section class="absolute inset-y-0 right-0 flex md:w-[29rem] w-full">
          <!-- Sidebar content -->
          <div class="relative w-screen max-w-md bg-white shadow-xl">
            <header class="flex items-center justify-between p-4">
              <button
                @click="closeCartSidebar"
                class="text-gray-500 focus:outline-none focus:text-gray-700"
              >
                <svg
                  class="w-5 h-5 font-bold text-black"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </button>
              <h2 class="text-sm font-medium"></h2>
            </header>
            <div class="flex flex-col px-5">
              <div class="p-4">
                <div v-if="cart.length === 0" class="text-center text-gray-500">
                  Votre panier est vide.
                </div>
                <div v-else>
                  <div
                    v-for="item in cart"
                    :key="item.id"
                    class="flex justify-between py-2 border-b"
                  >
                    <div>
                      <img
                        v-if="item"
                        :src="item.photos[0]"
                        class="w-16 h-20 rounded-sm"
                        alt=""
                      />
                    </div>
                    <div class="flex flex-col justify-between">
                      <p class="text-xs">{{ item.name }}</p>
                      <p class="text-xs">{{ formatCurrency(item.price) }}</p>
                      <div class="flex items-center space-x-3">
                        <button class="flex items-center justify-center" @click="decrementProduct(item)">
                          -
                        </button>
                        <p class="text-xs font-thin">{{ item.quantity }}</p>
                        <button class="flex items-center justify-center" @click="incrementProduct(item)">
                          +
                        </button>
                      </div>
                    </div>
                    <div class="flex flex-col justify-between">
                      <button
                        class="flex justify-end text-sm font-semibold text-black"
                        @click="removeProduct(item.id)"
                      >
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          stroke-width="0"
                          viewBox="0 0 24 24"
                          color="#000"
                          height="1em"
                          width="1em"
                          class="flex items-end text-end"
                          xmlns="http://www.w3.org/2000/svg"
                          style="color: rgb(0, 0, 0)"
                        >
                          <path
                            d="M15 2H9c-1.103 0-2 .897-2 2v2H3v2h2v12c0 1.103.897 2 2 2h10c1.103 0 2-.897 2-2V8h2V6h-4V4c0-1.103-.897-2-2-2zM9 4h6v2H9V4zm8 16H7V8h10v12z"
                          ></path>
                        </svg>
                      </button>
                      <p class="text-xs">
                        {{ formatCurrency(item.totalPrice) }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="absolute bottom-0 left-0 right-0">
                <div
                  class="flex flex-col items-end text-sm font-light text-center"
                >
                  <a
                    href="/cart"
                    class="flex items-center justify-center w-full h-16 px-4 py-2 text-sm text-white uppercase bg-black"
                  >
                  {{ $t("cart.goToCart") }}
                  </a>
                  <button v-if="settings"
                    @click="closeCartSidebar"
                    class="flex items-center justify-between w-full h-16 px-4 py-2 text-white"
                    :style="{ 'background-color': settings.colors.primaryColor }"
                  >
                    <div class="flex flex-col items-start">
                      <p class="text-xs uppercase">Total</p>
                      <p class="text-sm font-medium">
                        {{ currency }} {{ formatCurrency(finalTotal) }}
                      </p>
                    </div>
                    <p class="text-sm font-light uppercase">
                      {{ $t("cart.continueShopping") }}
                    </p>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
//   import { mapGetters } from "vuex";
import { notify } from "@kyvg/vue3-notification";
export default {
  name: "CartSideBar",
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
      settings: null,
      finalTotal: 0,
      cart: null,
      currency: null,
    };
  },
  mounted() {
    this.$store.dispatch("fetchSettings", this.items).then(() => {
      this.settings = this.$store.state.settings;
      this.currency = this.settings.strings.currency;
    });

    if (localStorage.getItem("cart")) {
      this.cart = JSON.parse(localStorage.getItem("cart"));
    }

    this.finalTotal = this.totalAmt();
  },
  computed: {
    showCartSidebar() {
      return this.$store.state.showCartSidebar;
    },
  },
  methods: {
    closeCartSidebar() {
      this.$store.dispatch("closeCartSidebar");
    },

    formatCurrency(value) {
      return new Intl.NumberFormat("en-US").format(value);
    },
    
    truncate(str, n) {
      return str.length > n ? str.substr(0, n - 1) + "..." : str;
    },

    removeProduct(product) {
      const cartItems = JSON.parse(localStorage.getItem("cart"));
      const index = cartItems.findIndex(({ id }) => id === product);
      cartItems.splice(index, 1);
      localStorage.setItem("cart", JSON.stringify(cartItems));
      this.cart = JSON.parse(localStorage.getItem("cart"));
      notify({
        type: "success",
        title: "Panier",
        text: "Produit supprimé avec succès",
      });
      window.location.href = "/cart";
    },

    cartSubTotal() {
      this.subtotal = 0;
      for (let product of this.$store.state.cart) {
        this.subtotal += Number(product.totalPrice);
        if (product.selectedOptions) {
          product.selectedOptions.forEach((item) => {
            this.subtotal += Number(item.price);
          });
        }
        if (product.selectedOption) {
          this.subtotal += Number(product.selectedOption.price);
        }
      }
      return this.subtotal;
    },

    totalAmt() {
      this.total = this.cartSubTotal();
      if (this.discount) {
        if (this.discount.expired == false) {
          this.discount.products.forEach((item) => {
            const cartItems = JSON.parse(localStorage.getItem("cart"));
            const index = cartItems.findIndex(({ id }) => id === item.id);
            if (cartItems[index]) {
              if (this.discount.percentage == 1) {
                this.discountAmount = this.discount.discount;
                this.total =
                  this.total - this.total * (this.discount.discount / 100);
              } else if (this.discount.percentage == 0) {
                this.discountAmount = this.discount.discount;
                this.total -= this.discount.discount;
              }
              cartItems[index].totalPrice = this.total;
              cartItems[index].discount = this.discountAmount;
              localStorage.setItem("cart", JSON.stringify(cartItems));

              // localStorage.addItem("discount", this.discount)
              // localStorage.getItem()
            }
          });
        }
      }
      return this.total;
    },

    incrementProduct(product) {
      const cartItems = JSON.parse(localStorage.getItem("cart"));
      const index = cartItems.findIndex(({ id }) => id === product.id);

      if (product.available_qty == 0) {
        notify({
          type: "error",
          title: "Cart",
          text: "Product out of stock",
        });
      } else {
        product.quantity++;
        if (product.discount_price != 0) {
          product.totalPrice = product.discount_price * product.quantity;
          // this.finalTotal = product.totalPrice
        } else {
          product.totalPrice = product.price * product.quantity;
          // this.finalTotal =  product.totalPrice
        }
        // this.finalTotal = product.totalPrice
        //  update totalPrice in cart in localStorage
        cartItems[index].totalPrice = product.totalPrice;
        cartItems[index].quantity = product.quantity;
        localStorage.setItem("cart", JSON.stringify(cartItems));
        this.$store.state.cart = JSON.parse(localStorage.getItem("cart"));
      }

      this.finalTotal = this.totalAmt();
    },

    decrementProduct(product) {
      const cartItems = JSON.parse(localStorage.getItem("cart"));
      const index = cartItems.findIndex(({ id }) => id === product.id);

      if (product.quantity > 1) {
        product.quantity--;
        if (product.discount_price != 0) {
          product.totalPrice = product.discount_price * product.quantity;
        } else {
          product.totalPrice = product.price * product.quantity;
        }
        cartItems[index].totalPrice = product.totalPrice;
        cartItems[index].quantity = product.quantity;
        localStorage.setItem("cart", JSON.stringify(cartItems));
        this.$store.state.cart = JSON.parse(localStorage.getItem("cart"));
      } else {
        this.removeProduct(product);
      }

      this.finalTotal = this.totalAmt();
    },

    sanitizeTitle(title) {
      var slug = "";
      // Change to lower case
      var titleLower = title.toLowerCase();
      // Letter "e"
      slug = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, "e");
      // Letter "a"
      slug = slug.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, "a");
      // Letter "o"
      slug = slug.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, "o");
      // Letter "u"
      slug = slug.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, "u");
      // Letter "d"
      slug = slug.replace(/đ/gi, "d");
      // Trim the last whitespace
      slug = slug.replace(/\s*$/g, "");
      // Change whitespace to "-"
      slug = slug.replace(/\s+/g, "-");

      return slug;
    },
  },
};
</script>
