<template>
  <div>
    <!-- Your main content -->

    <!-- Sidebar -->
    <div v-if="showSidebar" class="fixed inset-0 z-50 overflow-hidden">
      <div class="absolute inset-0 overflow-hidden">
        <div
          class="absolute inset-0 transition-opacity bg-gray-500 bg-opacity-75"
          @click="closeSidebar"
        ></div>

        <section class="absolute inset-y-0 left-0 flex pr-10 w-80">
          <!-- Sidebar content -->
          <div class="relative w-screen max-w-md bg-white shadow-xl">
            <header class="flex items-center justify-between p-4">
              <h2 class="text-sm font-medium"></h2>
              <button
                @click="closeSidebar"
                class="text-gray-500 focus:outline-none focus:text-gray-700"
              >
                <svg
                  class="w-5 h-5 font-bold text-black"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </button>
            </header>
            <div class="flex flex-col px-5">
              <div class="flex items-center space-x-2" v-if="user">
                <img :src="user.photo" class="rounded-full w-14 h-14" alt="" />
                <div>
                  <p class="text-sm font-medium text-black">{{ user.name }}</p>
                  <a href="/profile" class="text-sm text-green-500">{{ $t("manageAccount") }}</a>
                </div>
              </div>
              <div class="mt-8 space-y-4 font-medium" v-if="user">
                <a href="/orders" class="flex items-center space-x-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    class="w-5 h-5"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12 1.5c-1.921 0-3.816.111-5.68.327-1.497.174-2.57 1.46-2.57 2.93V21.75a.75.75 0 0 0 1.029.696l3.471-1.388 3.472 1.388a.75.75 0 0 0 .556 0l3.472-1.388 3.471 1.388a.75.75 0 0 0 1.029-.696V4.757c0-1.47-1.073-2.756-2.57-2.93A49.255 49.255 0 0 0 12 1.5Zm3.53 7.28a.75.75 0 0 0-1.06-1.06l-6 6a.75.75 0 1 0 1.06 1.06l6-6ZM8.625 9a1.125 1.125 0 1 1 2.25 0 1.125 1.125 0 0 1-2.25 0Zm5.625 3.375a1.125 1.125 0 1 0 0 2.25 1.125 1.125 0 0 0 0-2.25Z"
                      clip-rule="evenodd"
                    />
                  </svg>

                  <span> {{ $t("orders.orders") }} </span>
                </a>
                <a href="/favourites" class="flex items-center space-x-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    class="w-5 h-5"
                  >
                    <path
                      d="m11.645 20.91-.007-.003-.022-.012a15.247 15.247 0 0 1-.383-.218 25.18 25.18 0 0 1-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0 1 12 5.052 5.5 5.5 0 0 1 16.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 0 1-4.244 3.17 15.247 15.247 0 0 1-.383.219l-.022.012-.007.004-.003.001a.752.752 0 0 1-.704 0l-.003-.001Z"
                    />
                  </svg>

                  <span> {{ $t("profile.myFavourites") }}myFavourites </span>
                </a>

                <button class="my-2 font-light text-gray-500" @click="logout">
                  {{ $t("login.SignOut") }}
                </button>
              </div>
              <hr class="my-5" />
              <div>
                <div class="space-y-2" v-if="!user">
                  <a
                    href="/register"
                    class="flex items-center justify-center w-full p-2 text-xl text-center text-white bg-black rounded-lg h-14"
                    >{{ $t("login.SignUp") }}</a
                  >
                  <a
                    href="/register"
                    class="flex items-center justify-center w-full p-2 text-xl text-center text-black bg-gray-100 rounded-lg h-14"
                    >{{ $t("login.login") }}</a
                  >
                </div>
                <div class="flex flex-col mt-3 space-y-3">
                  <a
                    target="__blank"
                    :href="`${this.$store.state.baseUrl}register/vendor`"
                    class="text-sm font-medium"
                    >{{ $t("createAccount") }}</a
                  >
                  <a
                    target="__blank"
                    :href="`${this.$store.state.baseUrl}register/driver`"
                    class="text-sm font-medium"
                    >{{ $t("signUpDelivery") }}</a
                  >
                </div>
                <div class="absolute bottom-8" v-if="settings">
                  <a href="/" class="text-2xl font-bold">
                    {{ settings.strings.company_name }}
                  </a>
                  <div class="flex justify-between mt-5 space-x-3">
                    <a
                      :href="settings.strings.iosDownloadLink"
                      target="_blank"
                      class="flex items-center justify-center p-2 space-x-2 text-sm text-black bg-gray-200 rounded-full"
                    >
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        viewBox="0 0 24 24"
                        class="w-3 h-3"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M14.268 4.231c.649-.838 1.14-2.022.963-3.231-1.061.074-2.301.752-3.025 1.637-.66.802-1.201 1.994-.99 3.152 1.16.036 2.357-.66 3.052-1.558zM20 15.602c-.464 1.035-.688 1.497-1.285 2.413-.834 1.28-2.01 2.872-3.47 2.884-1.294.014-1.628-.849-3.385-.838-1.758.01-2.124.854-3.421.841-1.458-.013-2.572-1.45-3.406-2.729-2.334-3.574-2.58-7.769-1.14-10C4.916 6.587 6.53 5.66 8.048 5.66c1.543 0 2.515.852 3.793.852 1.24 0 1.995-.854 3.78-.854 1.352 0 2.784.74 3.803 2.018-3.34 1.842-2.8 6.642.576 7.925z"
                        ></path>
                      </svg>
                      <span> iPhone </span>
                    </a>
                    <a
                      :href="settings.strings.androidDownloadLink"
                      target="_blank"
                      class="flex items-center justify-center p-2 space-x-2 text-sm text-black bg-gray-200 rounded-full"
                    >
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        viewBox="0 0 24 24"
                        class="w-3 h-3"
                      >
                        <g>
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M15.297 3.415l-.233.343c1.77.804 2.97 2.326 2.97 4.069H6.341c0-1.743 1.199-3.265 2.97-4.069l-.234-.343-.233-.338-.52-.761a.2.2 0 01.058-.282.214.214 0 01.29.057l.793 1.157.238.348a7.035 7.035 0 012.484-.444c.888 0 1.729.16 2.484.444l1.032-1.505a.21.21 0 01.288-.057.198.198 0 01.059.282l-.52.76-.234.339zm-1.23 2.176c0 .337.28.61.626.61a.618.618 0 00.627-.61.617.617 0 00-.627-.61.617.617 0 00-.627.61zm-4.385.61a.618.618 0 01-.627-.61c0-.338.28-.61.627-.61.346 0 .627.272.627.61 0 .337-.28.61-.627.61z"
                          ></path>
                          <path
                            d="M6.342 8.639h11.692v8.942c0 .71-.592 1.288-1.322 1.288h-.956c.033.107.052.22.052.338v2.574c0 .673-.562 1.22-1.254 1.22s-1.253-.547-1.253-1.22v-2.574c0-.119.018-.23.05-.338h-2.327c.032.107.051.22.051.338v2.574c0 .673-.562 1.22-1.253 1.22-.692 0-1.254-.547-1.254-1.22v-2.574c0-.119.018-.23.05-.338h-.953c-.73 0-1.323-.578-1.323-1.288V8.639zm-2.089 0C3.561 8.639 3 9.185 3 9.858v5.216c0 .673.56 1.22 1.253 1.22.692 0 1.253-.547 1.253-1.22V9.858c0-.673-.561-1.219-1.253-1.219zM18.87 9.858c0-.673.56-1.219 1.253-1.219.691 0 1.252.546 1.252 1.219v5.216c0 .673-.56 1.22-1.252 1.22-.693 0-1.254-.547-1.254-1.22V9.858z"
                          ></path>
                        </g>
                      </svg>
                      <span> Android </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
//   import { mapGetters } from "vuex";
export default {
  name: "SideBar",
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
      settings: null,
    };
  },
  mounted() {
    this.$store.dispatch("fetchSettings", this.items).then(() => {
      this.settings = this.$store.state.settings;
    });
  },
  computed: {
    showSidebar() {
      return this.$store.state.showSidebar;
    },
  },
  methods: {
    openSidebar() {
      this.$store.dispatch("openSidebar");
    },
    closeSidebar() {
      this.$store.dispatch("closeSidebar");
    },
    logout() {
      localStorage.removeItem("authToken");
      localStorage.removeItem("phone");
      localStorage.removeItem("user");
      window.location.href = "/";
    },
  },
};
</script>
