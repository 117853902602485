<template>
  <div
    class="p-2 space-x-2 bg-[#EEEEEE] rounded-full cursor-pointer w-auto h-[48px] items-center md:flex mx-5 hidden"
    v-on:click="toggleModal()"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      fill="currentColor"
      class="w-4 h-4"
    >
      <path
        fill-rule="evenodd"
        d="m7.539 14.841.003.003.002.002a.755.755 0 0 0 .912 0l.002-.002.003-.003.012-.009a5.57 5.57 0 0 0 .19-.153 15.588 15.588 0 0 0 2.046-2.082c1.101-1.362 2.291-3.342 2.291-5.597A5 5 0 0 0 3 7c0 2.255 1.19 4.235 2.292 5.597a15.591 15.591 0 0 0 2.046 2.082 8.916 8.916 0 0 0 .189.153l.012.01ZM8 8.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"
        clip-rule="evenodd"
      />
    </svg>

    <span class="text-sm truncate">
      {{ currentLocation ? truncate(currentLocation.formatted_address, 47) ?? currentPlace : "Sélectionnez une adresse" }}
    </span>
  </div>
  <div
    v-if="showModal"
    class="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none"
  >
    <div class="relative h-full p-2 md:p-4 md:w-3/12 md:h-auto" v-if="settings">
      <div class="relative bg-white rounded-lg shadow">
        <button
          type="button"
          class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
          v-on:click="toggleModal()"
          data-modal-toggle="authentication-modal"
        >
          <svg
            aria-hidden="true"
            class="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
          <span class="sr-only">Close modal</span>
        </button>
        <div class="px-6 py-6 lg:px-8">
          <h3 class="mb-4 text-2xl font-medium text-gray-900">
            Détails de livraison
          </h3>
          <span class="flex flex-col p-5 bg-white rounded-lg">
            <h1 class="mb-2 font-medium" v-if="!currentLocation">Livrer à</h1>
            <div class="flex items-center justify-between" v-if="currentLocation">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                class="w-5 h-5"
              >
                <path
                  fill-rule="evenodd"
                  d="m11.54 22.351.07.04.028.016a.76.76 0 0 0 .723 0l.028-.015.071-.041a16.975 16.975 0 0 0 1.144-.742 19.58 19.58 0 0 0 2.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 0 0-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 0 0 2.682 2.282 16.975 16.975 0 0 0 1.145.742ZM12 13.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
                  clip-rule="evenodd"
                />
              </svg>
              <div>
                <p class="flex flex-col text-sm font-light">
                  <span class="font-medium">{{ currentLocation.name }}</span>
                  <span class="font-light">{{
                    truncate(currentLocation.formatted_address, 20)
                  }}</span>
                </p>
              </div>
              <button
                class="w-16 p-2 text-xs text-black rounded-full bg-slate-200"
              >
                change
              </button>
            </div>

            <GMapAutocomplete
              v-if="!currentLocation"
              placeholder="Recherchez votre emplacement"
              @place_changed="setPlace"
              class="w-full h-12 px-3 py-3 border border-gray-200 rounded-lg bg-gray-50 placeholder:font-light sm:text-sm"
            >
            </GMapAutocomplete>

            <button
              v-if="!currentLocation"
              @click="addressSave"
              class="flex items-center justify-center w-full h-12 p-2 mt-4 font-light text-white bg-green-500 rounded-full cursor-pointer"
              :style="{ 'background-color': settings.colors.primaryColor }"
              type="button"
            >
              Sauvegarder
            </button>
          </span>
        </div>
      </div>
    </div>
  </div>
  <div v-if="showModal" class="fixed inset-0 z-40 bg-black opacity-25"></div>
</template>
<script>
export default {
  data() {
    return {
      showModal: false,
      settings: null,
      currentPlace: "",
      autocomplete: null,
      currentLocation: "",
    };
  },
  mounted() {
    // this.getCurrentLocation();
    this.$store.dispatch("fetchSettings", this.items).then(() => {
      this.settings = this.$store.state.settings;
    });

    this.currentLocation = JSON.parse(localStorage.getItem("currentAddress"));
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const currentPlace = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          };
          fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${currentPlace.latitude},${currentPlace.longitude}&key=${this.settings.strings.google_maps_key}`
          )
            .then((response) => response.json())
            .then((data) => {
              if (data.results && data.results.length > 0) {
                const locationName = data.results[0].formatted_address;
                this.setPlace(locationName);
                this.currentPlace = locationName;
              } else {
                console.error("No results found");
              }
            })
            .catch((error) => {
              console.error("Error fetching location data:", error);
            });
        },
        (error) => {
          console.error("Error getting location:", error.message);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
    // this.getUserLocation();
  },
  methods: {
    // getCurrentLocation() {
    //   if (navigator.geolocation) {
    //     navigator.geolocation.getCurrentPosition(this.getGeolocationSuccess, this.getGeolocationError);
    //   } else {
    //     console.error('Geolocation is not supported by this browser.');
    //   }
    // },
    // getGeolocationSuccess(position) {
    //   const { latitude, longitude } = position.coords;
    //   const latlng = new google.maps.LatLng(latitude, longitude);

    //   const geocoder = new google.maps.Geocoder();
    //   geocoder.geocode({ location: latlng }, (results, status) => {
    //     if (status === 'OK') {
    //       if (results[0]) {
    //         this.currentPlace = results[0].formatted_address;
    //       } else {
    //         console.error('No results found');
    //       }
    //     } else {
    //       console.error('Geocoder failed due to: ' + status);
    //     }
    //   });
    // },
    // getGeolocationError(error) {
    //   console.error(`Geolocation error: ${error.message}`);
    // },

    addressSave() {
      localStorage.setItem("currentAddress", JSON.stringify(this.currentPlace));
      window.location.reload()
    },

    toggleModal: function () {
      this.showModal = !this.showModal;
    },

    setPlace(place) {
      this.currentPlace = place;
    },

    truncate(str, n) {
      return str.length > n ? str.substr(0, n - 1) + "..." : str;
    },
  },
};
</script>
