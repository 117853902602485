export default {
  "manageAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gérer son compte"])},
  "createAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un compte professionnel"])},
  "signUpDelivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscrivez-vous pour livrer"])},
  "searchHere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cherche ici"])},
  "deliveryLandingPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commandez une livraison près de chez vous"])},
  "stores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Des magasins pour vous aider à économiser"])},
  "addRestaurant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajoutez votre restaurant"])},
  "deliverWith": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livrer avec"])},
  "landingpageLeft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rend les achats en ligne de produits alimentaires, d'épicerie, de livraison de colis et de pharmacie rapides et faciles. Obtenez des produits d'épicerie livrés et commandez vos plats préférés auprès des meilleurs fournisseurs."])},
  "landingpageHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout ce dont vous avez besoin, livré maintenant"])},
  "landingpageSubHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commandez de la nourriture en ligne, faites livrer des produits d'épicerie et faites vos achats en ligne avec le meilleur supermarché de votre région."])},
  "landingpageButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Découvrez les fournisseurs locaux qui livrent à votre porte"])},
  "landingpageJoin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejoignez le mouvement des affaires faciles"])},
  "landingpageJoinSubHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejoignez le mouvement commercial facile et découvrez un chemin plus simple vers le succès. Montez à bord dès maintenant et vivez l'esprit d'entreprise sans tracas."])},
  "landingpageSellerHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devenez vendeur"])},
  "landingpageSellerSubHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscrivez-vous en tant que vendeur et ouvrez un magasin pour démarrer votre entreprise dès aujourd'hui."])},
  "landingpageSellerButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscription"])},
  "landingpageSellerFeat1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options de paiement multiples"])},
  "landingpageSellerFeat2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion facile des commandes"])},
  "landingpageSellerFeat3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offrant plusieurs options de paiement"])},
  "landingpageSellerFeat4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation de A à Z."])},
  "landingpageRide": {
    "landingpageRiderHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devenez cycliste avec nous et gagnez plus"])},
    "landingpageRiderSubHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejoignez notre équipe en tant que cycliste et libérez votre potentiel de revenus. Profitez de la liberté d'horaires flexibles et de salaires compétitifs tout en apportant du bonheur aux clients."])},
    "landingpageRiderButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devenir un cycliste"])}
  },
  "downloadPage": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facilitez votre boutique en ligne avec notre application mobile"])},
    "subHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimisez votre boutique en ligne facilement en utilisant notre application mobile. Gérez votre entreprise en déplacement et restez connecté avec vos clients à tout moment et en tout lieu."])}
  },
  "footer": {
    "header1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entreprise"])},
    "sub1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactez-nous"])},
    "sub2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politique de confidentialité"])},
    "sub3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termes et conditions"])},
    "header2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faire des affaires avec nous"])},
    "sub4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besoin de passagers ou de conducteurs?"])},
    "sub5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je veux vendre à"])},
    "header3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte"])},
    "sub6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gérer le compte"])},
    "sub7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commandes"])},
    "header4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
    "sub8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trouver un magasin"])},
    "header5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégories"])},
    "copyright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Droits d'auteur"])},
    "copyright2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tous droits réservés"])}
  },
  "cookieText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous utilisons des cookies pour améliorer votre expérience sur notre site Web. En naviguant sur ce site Web, vous acceptez notre utilisation des cookies."])},
  "cookieButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepter"])},
  "nav": {
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])}
  },
  "pages": {
    "bestselling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meilleure vente"])},
    "newest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveauté"])},
    "featured": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En vedette"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche"])},
    "searchPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher des produits"])},
    "searchButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher"])},
    "searchResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultats de recherche"])},
    "searchResult2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultats de recherche pour"])},
    "searchResult3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun résultat trouvé"])},
    "searchResult4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun résultat trouvé pour"])},
    "seeall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir tout"])},
    "seemore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir plus"])},
    "seeless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir moins"])},
    "nearbyvendors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendeurs à proximité"])},
    "foryou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour vous"])},
    "vendors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fournisseurs populaires"])},
    "vendorSubHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trouvez les meilleurs fournisseurs de votre région"])},
    "vendorSubHeader2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous avons tous vos fournisseurs préférés ici."])},
    "newArrivals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveautés"])},
    "newArrival": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveauté"])},
    "flashSales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ventes flash"])},
    "flashSale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vente flash"])},
    "books": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livres"])},
    "book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livre"])},
    "todaysPicks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choix du jour"])},
    "noVendors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun vendeur"])},
    "chooseFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisir le fichier"])},
    "popularServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services populaires"])},
    "topRated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les mieux notés"])}
  },
  "Instock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En stock"])},
  "Outofstock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En rupture de stock"])},
  "BestSeller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meilleure vente"])},
  "hot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chaud"])},
  "product": {
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantité"])},
    "addtocart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter au panier"])},
    "recommended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommandé pour vous"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer"])},
    "optionHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options"])},
    "sameVendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produits du même fournisseur"])},
    "optionSubHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez des options pour les ajouter au produit."])}
  },
  "cart": {
    "cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panier"])},
    "goToCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aller au panier"])},
    "continueShopping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer vos achats"])},
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre panier"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix"])},
    "totalProductPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix total du produit"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifier"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre panier est vide"])},
    "empty2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre panier est vide. Commencez à magasiner maintenant."])},
    "extras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extras"])},
    "noExtras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun extra disponible"])},
    "orderSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résumé de la commande"])},
    "orderSummary2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce sous-total n'inclut pas les frais de livraison ou de retrait"])},
    "totalItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total des articles"])},
    "subtotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sous-total"])},
    "delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livraison"])},
    "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remise"])},
    "coupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coupon"])},
    "totalAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant total"])},
    "haveAnAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avoir un compte?"])},
    "useADiscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisez un code de réduction"])},
    "getDiscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obtenir une réduction"])},
    "shoppingWithSSLEncryption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achats avec cryptage SSL"])},
    "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joindre"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ou"])},
    "forAsmootherCheckout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pour un paiement plus fluide"])},
    "continueToCheckout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer le paiement"])}
  },
  "login": {
    "SignOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se déconnecter"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["se connecter"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
    "passwordDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre mot de passe doit contenir au moins 8 caractères, 1 majuscule, 1 minuscule et 1 chiffre"])},
    "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe oublié?"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'e-mail ou le mot de passe est incorrect"])},
    "dontHaveAnAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas de compte?"])},
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenue de nouveau chez Spidi!"])},
    "alreadyHaveAnAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez déjà un compte?"])},
    "checkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En créant un compte, vous acceptez notre politique de confidentialité et nos conditions générales"])},
    "SignUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un compte"])},
    "SignUpLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créez un compte"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom complet"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de téléphone"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
    "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["État"])},
    "zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code postal"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays"])},
    "password2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer le mot de passe"])}
  },
  "GroceryHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commandez ici votre supermarché préféré de différentes catégories"])},
  "pickupOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commande à récupérer"])},
  "pharmacyIndicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indiquez si vous récupérerez la commande auprès du fournisseur"])},
  "selectAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez l'adresse"])},
  "paymentMethods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Méthodes de paiement"])},
  "placeOrderRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passer une demande de commande"])},
  "deliveryAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse de livraison"])},
  "addAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une adresse"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier"])},
  "orderReady": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La commande sera prête pour"])},
  "OrderTaxi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commander un taxi"])},
  "vehicleType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de véhicule"])},
  "parcel": {
    "toPay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À qui payer?"])},
    "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expéditeur"])},
    "receiver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinataire"])},
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commande de ramassage de colis aujourd'hui"])},
    "modalTitile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations sur les paramètres et les destinataires du colis"])},
    "modalSubTitile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinataires du colis"])},
    "modalSubTitile2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres du colis"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payer"])},
    "subHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyez des colis ou des paquets à vos amis et votre famille."])},
    "trackPackage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivre votre colis"])},
    "orderNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commander maintenant"])},
    "recentOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commandes récentes"])},
    "sendPackages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer des colis"])},
    "subHeader2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre service de messagerie local à la demande"])},
    "clearAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout effacer"])},
    "packageTypes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Types de colis"])},
    "selectPackageType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez un type de colis"])},
    "courierVendors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fournisseurs de messagerie"])},
    "selectCourierVendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez un fournisseur de messagerie"])},
    "deliveryInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations de livraison"])},
    "pickupDestination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajoutez la collecte et la destination ici"])},
    "fromLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De"])},
    "stopLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Où ..."])},
    "pickupLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez un lieu de ramassage"])},
    "dropoffLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez un lieu de livraison"])},
    "addStops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter des arrêts"])},
    "chooseTypes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez un type de colis"])},
    "choosesCoourier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez un coursier"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heure"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivant"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Précédent"])},
    "recipients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinataires"])},
    "recipientInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations sur le destinataire"])},
    "contactInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coordonnées"])},
    "notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes"])},
    "disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En confirmant que j'accepte que cette commande ne contient pas d'articles illégaux/restreints, si des articles illégaux/restreints sont trouvés par "])},
    "disclaimer2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partenaire, ils peuvent le signaler aux autorités policières. Les termes et conditions s'appliquent."])},
    "packageParameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres du colis"])},
    "subHeader3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez vous assurer que le colis est complet et que tous les articles sont inclus dans le colis. Si le colis n'est pas complet, nous ne pouvons pas le recevoir."])},
    "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poids"])},
    "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Longueur"])},
    "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Largeur"])},
    "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hauteur"])},
    "deliveryDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails de livraison"])},
    "pickup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ramassage"])},
    "dropoffs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livraisons"])},
    "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement"])},
    "distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance"])},
    "deliveryCharges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frais de livraison"])},
    "packageSizeCharges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frais de taille du colis"])},
    "recipientTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinataire"])},
    "recipientEmptyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nom du destinataire est requis"])},
    "recipientEmptyPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le numéro de téléphone du destinataire est requis"])}
  },
  "checkout": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment souhaitez-vous recevoir votre commande?"])},
    "yourOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre commande"])},
    "proceedToChceckout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procéder à la caisse"])},
    "acceptedPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous acceptons les options de paiement suivantes"])},
    "orderSummaryText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce sous-total inclut les frais de livraison ou de retrait"])},
    "deliveryFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frais de livraison"])},
    "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impôt"])},
    "driverTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pourboire au chauffeur"])},
    "placeOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passer la commande"])}
  },
  "orders": {
    "Orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordres"])},
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes commandes"])},
    "accountSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres du compte"])},
    "subHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas encore passé de commande chez nous. Lorsque vous le ferez, elles apparaîtront ici."])},
    "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produit"])}
  },
  "profile": {
    "personalInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations personnelles"])},
    "Hello": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonjour"])},
    "passwordChange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changement de mot de passe"])},
    "myFavourites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes favoris"])},
    "oldPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ancien mot de passe"])},
    "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau mot de passe"])},
    "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer le mot de passe"])},
    "updatePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualiza contraseña"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
    "updateProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mise à jour du profil"])},
    "noAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune adresse disponible"])}
  }
}