<template>
  <Disclosure
    as="nav"
    :class="{ shadow: isScrolled }"
    class="fixed inset-x-0 top-0 left-0 z-50 bg-white"
    v-if="settings"
  >
    <div class="max-w-full px-3 mx-auto md:px-8">
      <div
        class="flex items-center justify-between py-4 space-x-4 md:space-x-8"
      >
        <div class="">
          <DisclosureButton
            class="relative inline-flex items-center justify-center p-2 text-gray-400 rounded-md hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
            @click="openSidebar"
          >
            <span class="absolute -inset-0.5" />
            <span class="sr-only">Open main menu</span>
            <Bars3Icon class="block w-6 h-6 font-extrabold text-black" />
            <!-- <XMarkIcon v-else class="block w-6 h-6" aria-hidden="true" /> -->
          </DisclosureButton>
        </div>
        <a
          href="/"
          class="hidden px-2 text-2xl font-bold text-black md:px-8 md:flex"
        >
          {{ settings.strings.app_name }}
        </a>
        <HeaderTabs />

        <LocationPicker />

        <div class="hidden w-full lg:flex">
          <ProductSearch
            ref="inputFieldRef"
            :vendorTypeRouteId="vendorTypeRouteId"
          />
        </div>

        <!-- <div class="flex flex-row"> -->
        <button
          @click="openCartSidebar"
          class="flex items-center justify-center w-auto h-12 px-4 py-2 text-sm text-center text-white rounded-full shadow"
          :style="{ 'background-color': settings.colors.primaryColor }"
        >
          <svg
            aria-hidden="true"
            focusable="false"
            viewBox="0 0 16 16"
            class="text-white fill-white h-[20px] w-[20px]"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M3.666 11.333h10.333l1.334-8h-11l-.267-2h-3.4v2h1.667l1.333 8zm1.333 3.334A1.333 1.333 0 105 12a1.333 1.333 0 000 2.667zm9.334-1.334a1.333 1.333 0 11-2.667 0 1.333 1.333 0 012.667 0z"
            ></path>
          </svg>
          <p class="px-1 text-sm">{{ cartItemCount }}</p>
          <span class="">{{ $t("cart.cart") }}</span>
        </button>
        <!-- </div> -->
      </div>
    </div>
  </Disclosure>

  <Sidebar />
  <CartSideBar />
</template>
<script>
import HeaderTabs from "@/components/Default/partials/HeaderTabs.vue";
import Sidebar from "@/components/Default/partials/Sidebar.vue";
import CartSideBar from "@/components/Default/partials/CartSideBar.vue";
import LocationPicker from "@/components/Default/widgets/LocationPicker.vue";
import ProductSearch from "@/components/Default/widgets/ProductSearch.vue";
export default {
  components: {
    HeaderTabs,
    Sidebar,
    LocationPicker,
    ProductSearch,
    CartSideBar,
  },
  data() {
    return {
      settings: null,
      vendorTypeRouteId: null,
    };
  },
  mounted() {
    this.vendorTypeRouteId = this.$route.params.id;
    this.$store.dispatch("fetchSettings", this.items).then(() => {
      this.settings = this.$store.state.settings;
    });
  },

  computed: {
    cartItemCount() {
      return this.$store.getters.count;
    },
  },
  methods: {
    openModalFromParent() {
      // Access the child component's method
      this.$refs.inputFieldRef.toggleModal();
    },

    setPlace(place) {
      this.currentPlace = place;
    },

    openSidebar() {
      this.$store.dispatch("openSidebar");
    },
    openCartSidebar() {
      this.$store.dispatch("openCartSidebar");
    },
  },
};
</script>
<script setup>
import { ref, onMounted, onBeforeUnmount } from "vue";
import { Disclosure, DisclosureButton } from "@headlessui/vue";
import { Bars3Icon } from "@heroicons/vue/24/outline";

const isScrolled = ref(false);

const handleScroll = () => {
  isScrolled.value = window.scrollY > 0;
};

onMounted(() => {
  window.addEventListener("scroll", handleScroll);
});

onBeforeUnmount(() => {
  window.removeEventListener("scroll", handleScroll);
});
</script>
