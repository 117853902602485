<template>
  <div class="flex p-1 space-x-2 bg-[#EEEEEE] rounded-full">
    <div
      v-for="tab in tabs"
      :key="tab.id"
      @click="selectTab(tab.id)"
      :class="{
        'bg-white text-black': selectedTab === tab.id,
        'text-black': selectedTab !== tab.id,
      }"
      class="w-20 px-2 py-2 text-sm font-medium text-center transition duration-300 rounded-full cursor-pointer"
    >
      {{ tab.label }}
    </div>
  </div>
</template>

<script>
export default {
    name: "HeaderTabs",
  data() {
    return {
      tabs: [
        { id: 1, label: "Delivery" },
        { id: 2, label: "Pickup" },
        // { id: 3, label: "Dine In" },
      ],
      selectedTab: 1,
    };
  },
  methods: {
    selectTab(tabId) {
      this.selectedTab = tabId;
    },
  },
};
</script>

<style scoped>
/* Add your component-specific styles here */
</style>
