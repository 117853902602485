<template>
  <div v-if="settings">
    <div
      class="w-screen h-screen bg-no-repeat bg-cover"
      :style="{
        'background-image':
          'url(' + settings.strings.website.websiteIntroImage + ')',
          'width': '100%',
      }"
    >
      <div class="container max-w-full px-5 mx-auto">
        <div class="flex flex-col items-start justify-center h-screen p-8">
          <h1
            class="text-xl font-semibold text-center text-black md:tracking-wider md:text-4xl md:mt-5"
          >
            {{ $t("deliveryLandingPage") }}
          </h1>
          <div
            class="sticky flex flex-col items-center w-full md:space-x-3 lg:flex-row"
          >
            <div
              class="flex items-center justify-between w-full p-3 mt-10 bg-white shadow-md h-14 lg:w-4/12 GMapAutocomplete"
            >
              <svg
                width="24px"
                height="24px"
                fill="none"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                focusable="false"
              >
                <path
                  d="M17.5834 5.16602C14.5001 2.08268 9.50008 2.08268 6.41675 5.16602C3.33341 8.24935 3.33341 13.3327 6.41675 16.416L12.0001 21.9993L17.5834 16.3327C20.6667 13.3327 20.6667 8.24935 17.5834 5.16602ZM12.0001 12.416C11.0834 12.416 10.3334 11.666 10.3334 10.7493C10.3334 9.83268 11.0834 9.08268 12.0001 9.08268C12.9167 9.08268 13.6667 9.83268 13.6667 10.7493C13.6667 11.666 12.9167 12.416 12.0001 12.416Z"
                  fill="currentColor"
                ></path>
              </svg>
              <GMapAutocomplete
                class="w-full p-2 text-black"
                placeholder="Entrez l'adresse de livraison"
                @place_changed="setPlace"
              >
              </GMapAutocomplete>
            </div>
            <button
              @click="getProductsAndVendorsByLocation"
              class="flex items-center justify-center w-full p-3 mt-3 font-semibold text-center text-white bg-black border-none rounded-md lg:mt-10 h-14 lg:w-1/12"
            >
            {{ $t("searchHere") }}
            </button>
          </div>
          <p class="mt-5 text-sm text-black" v-show="!isLoggedIn">
            or <a href="/login" class="underline">{{ $t("login.login") }}</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from "axios";
// import router from "@/router";
export default {
  name: "HomeBanner",
  components: {},
  data() {
    return {
      search: null,
      base_url: this.$store.state.baseUrl,
      selectedLanguage: this.$i18n.locale,
      availableLocales: ["en", "es", "ar"],
      settings: null,
      // isDropdownOpen: false,
      isLoggedIn: false,
      autocomplete: null,
      currentPlace: null,
      markers: [],
      // selectedMenuItem: "Deliver Now",
    };
  },
  watch: {
    selectedLanguage(newLang) {
      localStorage.setItem("selectedLanguage", newLang);
      this.$i18n.locale = newLang;
    },
  },
  mounted() {
    // document.addEventListener("click", this.closeDropdownOnOutsideClick);

    this.$store.dispatch("fetchSettings").then(() => {
      this.settings = this.$store.state.settings;
    });

    if (localStorage.getItem("authToken")) {
      this.isLoggedIn = true;
    }

    navigator.geolocation.getCurrentPosition((position) => {
      this.markers.position = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };
    });
  },

  methods: {
    getProductsAndVendorsByLocation() {
      localStorage.setItem("currentAddress", JSON.stringify(this.currentPlace));

      window.location.href = "/stores";
    },
   
    setPlace(place) {
      this.currentPlace = place;
    },

  },
};
</script>
<style>
.GMapAutocomplete input {
  padding: 0.5rem;
  border: none;
  outline: none;
  border-radius: 0.25rem;
  font-size: 1rem;
  color: #333;
}
.GMapAutocomplete input:focus {
  border: none;
  box-shadow: none;
}
</style>
